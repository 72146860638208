import { FC } from 'react';
import Popup from 'reactjs-popup';
import { PopupPosition } from 'reactjs-popup/dist/types';
import clsx from 'clsx';

import { WORK_HOURS } from '@lib/miscellaneous';
import { Icon } from '../icon/Icon';
import { PhonesCard } from '../PhonesCard/PhonesCard';

import classes from './PhoneLink.module.scss';

const phoneDefault = '+380 63 999 99 11';

interface IProps {
  withPopup?: boolean;
  onlyIcon?: boolean;
  dark?: boolean;
  triggerContent?: JSX.Element;
  containerClassName?: string;
  position?: PopupPosition;
}

const PhoneLink: FC<IProps> = ({
  withPopup = false,
  triggerContent,
  containerClassName,
  position = 'bottom left',
  onlyIcon
}) => {
  const iconLink = (
    <button className={clsx(classes.btn, { [classes.btnWithoutPopup]: !withPopup })} aria-label="Телефон">
      {withPopup ? (
        <a href={`tel:${phoneDefault}`} className={classes.phone}>
          {phoneDefault}
        </a>
      ) : (
        <div className={classes.container}>
          <a href={`tel:${phoneDefault}`}>
            <p className={classes.phoneDefault}>{phoneDefault}</p>
          </a>
          <span className={classes.workHours}>Пн - нд&nbsp;{WORK_HOURS}</span>
        </div>
      )}
    </button>
  );

  return (
    <div className={containerClassName}>
      {withPopup ? (
        <Popup
          on={['click']}
          closeOnDocumentClick
          contentStyle={{
            zIndex: 1300,
            width: 'fit-content',
            background: 'rgb(255, 255, 255)',
            border: '1px solid #EBE4E4',
            padding: 0,
            borderRadius: 12
          }}
          arrowStyle={{
            display: 'none'
          }}
          trigger={() => (
            <button className={classes.btn} aria-label="Телефон">
              {onlyIcon ? (
                <Icon type="phoneContact" size={24} />
              ) : (
                <>
                  <p className={classes.phone}>{triggerContent || phoneDefault}</p>
                  <Icon type="arrowExpand" leftOffset={4} width={10} className={classes.arrowExpand} />
                </>
              )}
            </button>
          )}
          position={position}
        >
          <PhonesCard />
        </Popup>
      ) : (
        iconLink
      )}
    </div>
  );
};

export default PhoneLink;
