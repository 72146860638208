import Image from 'next/image';

import { WORKING_HOURS_EXT } from '@lib/miscellaneous';

import classes from './ContactsInfo.module.scss';

const phone = '+380 63 999 99 11';

interface IProps {
  withoutTitle?: boolean;
}

const ContactsInfo = ({ withoutTitle }: IProps) => (
  <div className={classes.contactsContainer}>
    {!withoutTitle ? <h5 className={classes.title}>Контакти</h5> : null}
    <div className={classes.phoneContainer}>
      <span className={classes.phoneNumber}>
        <a href={`tel:${phone}`} aria-label={phone}>
          {phone}
        </a>
      </span>
    </div>
    <div className={classes.workingHours}>
      <p>Час роботи:</p>
      <p>{WORKING_HOURS_EXT}</p>
      <a
        href="//www.dmca.com/Protection/Status.aspx?ID=59f0cd27-45a3-4063-a605-ea65d1717ee0"
        title="DMCA.com Protection Status"
      >
        <Image
          width={100}
          height={20}
          src="https://images.dmca.com/Badges/dmca-badge-w100-5x1-08.png?ID=59f0cd27-45a3-4063-a605-ea65d1717ee0"
          alt="DMCA.com Protection Status"
        />
      </a>
    </div>
  </div>
);

export { ContactsInfo };
