import { MainLogoLink } from '@components/shared/MainLogoLink/MainLogoLink';
import { SocialLinks } from '@components/shared/SocialLinks/SocialLinks';
import { AppLinks } from '@layout/footer/app-links/AppLinks';

import classes from './FooterSocialLinks.module.scss';

interface IProps {
  logoHeight?: number;
  logoWidth?: number;
}

const FooterSocialLinks = ({ logoHeight, logoWidth }: IProps) => (
  <div className={classes.wrapper}>
    <MainLogoLink height={logoHeight || 36} width={logoWidth || 200} classNameWrapper={classes.footerSocialLinks} />
    <SocialLinks />
    <AppLinks />
  </div>
);

export { FooterSocialLinks };
