import { FC } from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useSession } from 'next-auth/react';

import { Budge } from '@components/shared/budge/Budge';
import { Icon } from '@components/shared/icon/Icon';
import { useCartData } from '@contexts/cart-data';
import HeaderTopFavorites from '@layout/header/HeaderTopFavorites';

import classes from './AccountLinks.module.scss';

const AuthModal = dynamic(() => import('@components/modals').then((mod) => mod.AuthModal));

interface IAccountLinksProps {
  withoutUser?: boolean;
}
const AccountLinks: FC<IAccountLinksProps> = ({ withoutUser }) => {
  const { data: session, status: sessionStatus } = useSession();
  const { cart } = useCartData();
  const totalInCart = cart?.length ? cart.reduce((acc, c) => c.qty + acc, 0) : 0;
  const isAuthenticated = 'authenticated' === sessionStatus;
  let userInitials = '';
  if (isAuthenticated && session?.user) {
    // profile will exist in re-declared module, we need to trick IDE just here, but real type is next-auth/SessionUser
    const { profile, name = '' } = session.user || {};
    let { firstName = '', lastName = '' } = profile || {};
    if (!firstName && !lastName && name) {
      [firstName, lastName] = name.split(' ');
    }
    userInitials = `${firstName?.[0] || ''}${lastName?.[0] || ''}`;
  }
  return (
    <div className={classes.innerGridItemUser}>
      {!withoutUser ? (
        <div className={classes.rightIcons}>
          {sessionStatus === 'authenticated' ? (
            <Link href="/tp/profile" className={classes.userInitials} aria-label="Профіль">
              {userInitials}
            </Link>
          ) : (
            <AuthModal>
              <Icon type="profile" size={22} />
            </AuthModal>
          )}
        </div>
      ) : null}
      <div className={classes.rightIcons}>
        <HeaderTopFavorites favoritesQty={session?.user.watchList.length} />
      </div>
      <Link href="/tp/cart" className={classes.cartIconWrapper} aria-label="Кошик">
        <Icon type="cart" size={24} style={{ stroke: '#333', strokeWidth: 2 }} />
        {cart.length ? <Budge label={totalInCart} /> : null}
      </Link>
    </div>
  );
};

export default AccountLinks;
